<!-- 首页 -->
<template>
  <div>
    <van-pull-refresh
      v-model="isLoading"
      @refresh="onRefresh"
      class="container homeStyle"
      ref="container"
    >
      <!-- 顶部用户信息 -->
      <div class="topBox">
        <img src="@/assets/images/home/top.png" class="bg" />
        <template v-if="userInfo.nickname === '请登录'">
          <div class="userInfo" @click="toLogin">
            <div class="avatarWrap">
              <van-image
                class="avatar"
                round
                width="10rem"
                height="10rem"
                :src="userInfo.avatar"
              />
            </div>
            <div class="nicknameWrap">
              <div class="nickname">
                <span>{{ userInfo.nickname }}</span>
              </div>
              <div class="joinDays">欢迎加入聆课</div>
            </div>
          </div>
          <div class="exchange_btn" @click="toLogin">
            <img src="@/assets/images/home/eximg.png" alt="" />
            <div>兑换课程</div>
          </div>
        </template>
        <template v-else>
          <div class="userInfo">
            <div class="avatarWrap">
              <van-image
                class="avatar"
                round
                width="10rem"
                height="10rem"
                :src="userInfo.avatar"
              />
            </div>
            <div class="nicknameWrap">
              <div class="nickname">
                Hi
                <span>{{ userInfo.nickname }}</span>
              </div>
              <div class="joinDays" v-if="userInfo.joinDays">
                这是您加入学习的第
                <span>{{ userInfo.joinDays }}天</span>
              </div>
            </div>
          </div>
          <div class="exchange_btn" @click="showExchange">
            <img src="@/assets/images/home/eximg.png" alt="" />
            <div>兑换课程</div>
          </div>
        </template>
      </div>
      <!--课程列表-->
      <div class="homeWrap">
        <van-swipe
          indicator-color="white"
          indicator-position="top"
          class="swiper"
          @change="onChange"
          :autoplay="autoplay"
          :interval="interval"
        >
          <van-swipe-item
            v-for="(item, index) in tableData"
            class="van-swiper-item"
            :key="index"
            @click="toCourseDetails(item)"
          >
            <div class="courseName">{{ item.courseName }}</div>
            <div class="lecherWrap">
              <div class="lecherBox">
                <img
                  src="@/assets/images/home/jiangshi.png"
                  class="lecherAva"
                />
                <div class="lecherNames">{{ item.lecherNames }}</div>
              </div>
              <div class="lecherRight">
                <img src="@/assets/images/home/time.png" class="lecherAva" />
                <div class="publishDate">{{ item.publishDate }}</div>
              </div>
            </div>
            <div class="courseImgWrap">
              <img :src="item.courseImg" alt class="courseImg" />
              <div class="courseImgMeng">
                <div class="joinNumWrap">
                  <img
                    src="@/assets/images/home/dianbo.png"
                    alt
                    class="dianbo"
                  />
                  <div class="joinNum">已有{{ item.joinNum }}人加入本课程</div>
                </div>
                <div class="chapterCountsWrap">
                  <div class="chapterCounts">
                    共
                    <span class="updateNum">{{ item.chapterCounts }}</span
                    >集
                  </div>
                  <div class="chapterCounts" style="margin-left: 5px">
                    已更新
                    <span class="updateNum">{{ item.updateNum }}</span
                    >集
                  </div>
                </div>
              </div>
            </div>
            <div class="title">
              <span style="position: absolute">课程简介</span>
            </div>
            <div
              :class="
                item.clientHeight >= computedHeight
                  ? 'courseDescMore'
                  : 'courseDesc'
              "
              :style="{ 'max-height': computedHeight + 'px' }"
            >
              {{ item.courseDesc }}
            </div>
          </van-swipe-item>
          <template #indicator>
            <ul class="indicators indicator">
              <li
                v-for="(item, index) in tableData"
                :key="index"
                :class="{ active: checkActive(index) }"
              ></li>
            </ul>
          </template>
        </van-swipe>
      </div>
      <div class="bg2"></div>
      <!-- <div class="super_topic_wrap"></div> -->
    </van-pull-refresh>
    <van-popup v-model="exchangeShow" round position="bottom">
      <template v-if="exchangeSuccess">
        <div class="pay_wrap" v-if="beforeExchange">
          <div class="pay_info">
            <div class="pay_tip_no_refund">恭喜您，完成兑换！</div>
            <div class="pay_wx_bar flex_column">
              <div class="courseName">{{ exchangeInfo.courseName }}</div>
              <div class="lecherWrap">
                <div class="lecherBox">
                  <img
                    src="@/assets/images/home/jiangshi.png"
                    class="lecherAva"
                  />
                  <div class="lecherNames">{{ exchangeInfo.lecherNames }}</div>
                </div>
                <div class="lecherRight">
                  <img src="@/assets/images/home/time.png" class="lecherAva" />
                  <div class="publishDate">{{ exchangeInfo.publishDate }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="pay_bar">
            <div class="btn_class">
              <van-button
                plain
                type="primary"
                class="btn"
                @click="toExCourse(exchangeInfo)"
                >进入课程</van-button
              >
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="pay_wrap" v-if="beforeExchange">
          <div class="pay_info">
            <div class="pay_title">
              请输入兑换码，兑换成功后，将可观看完整课程
            </div>
            <!-- <div class="pay_tip_no_refund">请输入兑换码，兑换成功后，将可观看完整课程</div> -->
            <div class="pay_wx_bar">
              <van-field
                class="exchange_field read_only"
                v-model="message"
                label
                type="input"
                maxlength="8"
                readonly
              />
            </div>
            <div class="exchange_tip">您兑换的课程：</div>
            <div class="pay_wx_bar flex_column">
              <div class="courseName">{{ exchangeInfo.courseName }}</div>
              <!-- <div class="exchange_info">
              <div class="exchange_teacher">{{exchangeInfo.lecherNames}}</div>
              <div class="exchange_time">{{exchangeInfo.publishDate}}</div>
              </div>-->
              <div class="lecherWrap">
                <div class="lecherBox">
                  <img
                    src="@/assets/images/home/jiangshi.png"
                    class="lecherAva"
                  />
                  <div class="lecherNames">{{ exchangeInfo.lecherNames }}</div>
                </div>
                <div class="lecherRight">
                  <img src="@/assets/images/home/time.png" class="lecherAva" />
                  <div class="publishDate">{{ exchangeInfo.publishDate }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="pay_bar">
            <div class="btn_class">
              <van-button
                plain
                type="primary"
                class="btn"
                @click="clickExchange"
                >兑换课程</van-button
              >
            </div>
          </div>
        </div>
        <div class="pay_wrap" v-else>
          <div class="pay_info">
            <div class="pay_title">
              请输入兑换码，兑换成功后，将可观看完整课程
            </div>
            <!-- <div class="pay_tip_no_refund">请输入兑换码，兑换成功后，将可观看完整课程</div> -->
            <div class="pay_wx_bar">
              <van-field
                class="exchange_field"
                v-model="message"
                label
                type="input"
                maxlength="8"
                placeholder="请输入兑换码"
              />
            </div>
          </div>
          <div class="pay_bar">
            <div class="btn_class">
              <van-button
                plain
                type="primary"
                class="btn"
                @click="clickBeforeExchange"
                >查询课程</van-button
              >
            </div>
          </div>
        </div>
      </template>
    </van-popup>
    <router-view class></router-view>
  </div>
</template>

<script>
import _ from "lodash";
import { getToken } from "@/utils/auth";
import { Dialog, Toast } from "vant";
import { getMyInfo } from "@/api/login";
import { courseList, getExchangeInfo, postExchange } from "@/api/home";
import { setStore, getStore } from "@/utils/mUtils";
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {
    "van-dialog": Dialog.Component,
    Toast,
  },
  data() {
    return {
      message: "",
      exchangeInfo: {},
      exchangeSuccess: false,
      beforeExchange: false,
      exchangeShow: false,
      showArticle: false,
      tableData: [],
      count: 0,
      isLoading: false,
      userInfo: {
        avatar: "",
        nickname: "",
        joinDays: 0,
      },
      indicatorDots: true, //小点
      indicatorColor: "black", //指示点颜色
      activeColor: "gray", //当前选中的指示点颜色
      autoplay: "true", //是否自动轮播
      interval: 3000, //间隔时间
      duration: 1000, //滑动时间
      circular: true,
      currentActive: 0,
      computedHeight: 0,
      homeHeight: 0,
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {
    $route(to, from) {
      console.log("xxxxx");
    },
  },
  // 方法集合
  methods: {
    clickExchangeConfig() {
      Dialog.confirm({
        title: "",
        message: "您确认兑换课程",
      })
        .then(() => {
          // on confirm
          this.clickExchange();
        })
        .catch(() => {
          // on cancel
        });
    },
    async clickExchange() {
      const res = await postExchange({ code: this.message });
      this.exchangeSuccess = true;
    },
    showExchange() {
      this.exchangeShow = true;
      this.beforeExchange = false;
      this.exchangeSuccess = false;
      this.message = "";
      this.exchangeInfo = {};
    },
    async clickBeforeExchange() {
      const res = await getExchangeInfo({ code: this.message });
      console.log(res);
      this.exchangeInfo = res.data;
      this.beforeExchange = true;
    },
    async getList() {
      var that = this;
      const res = await courseList();
      this.tableData = res.data;
      this.$nextTick(() => {
        that.getHeight();
      });
      that.$forceUpdate();
      // console.log(that.tableData);
    },
    getHeight() {
      var that = this;
      // 动态获取 课程简介高 =》 根据不同高度 变成...
      let arr2 = Array(...document.getElementsByClassName("courseDesc"));
      let arr3 = [];
      for (let j = 0; j < arr2.length; j++) {
        arr3.push(arr2[j].clientHeight);
      }
      for (let j = 0; j < arr3.length; j++) {
        for (let i = 0; i < that.tableData.length; i++) {
          that.tableData[i].clientHeight = arr3[i];
        }
      }
      that.$forceUpdate();
    },
    toCourseDetails(cat) {
      // this.$router.getRoutes();
      // let childrenRoute = {
      //   path: "detail",
      //   name: "Detail",
      //   component: () => import("@/views/home/detail.vue"),
      //   meta: {
      //     title: "课程详情",
      //   },
      // };
      // this.$router.addRoute("home", childrenRoute);
      this.$router.push({
        path: `/home/detail`,
        query: { id: cat.id },
      });
    },
    toExCourse(cat) {
      this.exchangeShow = false;
      this.$router.push({
        path: `/home/detail`,
        query: { id: cat.courseId },
      });
    },
    onRefresh() {
      this.getList();
      this.userInfo = JSON.parse(getStore("userInfo"));
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
    },
    onLoad() {},
    hideOverlay() {
      this.showArticle = false;
    },
    onChange(index) {
      this.currentActive = index;
    },
    checkActive(index) {
      return index === this.currentActive;
    },
    async getMyInfoFunc() {
      const res = await getMyInfo();
      console.log("看看首页用户信息", res);
      if (res.code === 1) {
        this.userInfo = res.data;
        setStore("userInfo", res.data);
      } else {
        let info = {
          avatar: "https://onlinevideo.kmindedu.com/default/defaultuser.png",
          nickname: "请登录",
        };
        this.userInfo = info;
      }
    },
    initialHeight() {
      this.computedHeight = window.innerHeight - 600;
      console.log(this.computedHeight);
    },
    toLogin() {
      this.$router.push({
        path: "/welcome",
      });
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    //  this.initWXJSSDK({
    //   title:'聆课咨询学习平台',
    //   desc:'',
    //   link:location.href,
    //   imgUrl:'http://testlemneross.lemner.com/2025/02/24/7ab54ef0-75aa-4193-88b9-89ba9c4281b6.png',
    // })
    // 监听窗口大小变化事件
    this.getMyInfoFunc();
    this.getList();
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.initialHeight();
    window.addEventListener("resize", this.initialHeight);
    this.$EventBus.$on(
      "toSearch",
      // A发送来的消息
      this.toSearch
    );
    this.$EventBus.$on("hideOverlay", this.hideOverlay);
  },
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
    this.$EventBus.$off(
      "toSearch",
      // A发送来的消息
      this.toSearch
    );
    this.$EventBus.$off(
      "hideOverlay",
      // A发送来的消息
      this.hideOverlay
    );
  }, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="scss" scoped>
// @import "~@/styles/variable.scss";
$ListHeight: calc(100vh - 50px);
.container.homeStyle {
  height: $ListHeight;
  background: #f3f3f3;
  display: block;
  .topBox {
    position: relative;
    z-index: 3;
  }

  .bg {
    width: 100vw;
    height: 267px;
    position: absolute;
    top: 0;
    left: 0;
  }
  .bg2 {
    position: relative;
    bottom: -70px;
    left: 60px;
    width: calc(100% - 120px);
    height: 120px;
    border-radius: 30px;
    z-index: 3;
    background: #ffeccd1e;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.11);
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  .userInfo {
    position: absolute;
    display: flex;
    align-items: center;
    left: 30px;
    top: 40px;
  }
  .exchange_btn {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 30px;
    top: 65px;
    font-size: 26px;
    color: #ffffff;
    line-height: 32px;
    letter-spacing: 2px;
    width: 207px;
    height: 69px;
    background: #55b0f7;
    border-radius: 35px 35px 35px 35px;
    img {
      width: 45px;
      height: 46px;
    }
  }

  .avatarWrap {
    width: 124px;
    height: 124px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .avatar {
    width: 120px !important;
    height: 120px !important;
    border-radius: 50%;
    object-fit: cover;
  }

  .nicknameWrap {
    display: flex;
    flex-direction: column;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 36px;
    color: #ffffff;
    line-height: 42px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    margin-left: 10px;
  }

  .joinDays {
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 24px;
    color: #ffffff;
    line-height: 28px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    margin-top: 18px;
    opacity: 0.7;
  }

  .nickname {
    width: 360px;
    color: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .homeWrap {
    position: relative;
    z-index: 9;
    top: 210px;
    width: calc(100vw - 60px);
    margin: 0 auto;
  }
  .swiper {
    width: calc(100vw - 60px);
    height: calc(100vh - 420px);
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
    border-radius: 30px;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  .swiper .van-swiper-item {
    width: calc(100vw - 60px) !important;
    height: 100%;
    padding: 30px;
  }
  .indicators {
    position: absolute;
    bottom: 40px;
    left: 50%;
    display: flex;
    transform: translateX(-50%);
  }

  .indicator li {
    display: inline;
    width: 11px;
    height: 11px;
    background: linear-gradient(90deg, #ffc60a 0%, #fce4b7 100%), #d9d9d9;
    border-radius: 50%;
    opacity: 0.3;
    transition: opacity 0.5s, background-color 0.5s; //@animation-duration-fast
    &:not(:last-child) {
      margin-right: 6px;
    }

    &.active {
      width: 36px;
      height: 11px;
      background: linear-gradient(90deg, #ffc60a 0%, #fce4b7 100%), #d9d9d9;
      border-radius: 18px;
      opacity: 0.8;
    }
  }
  .courseName {
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 36px;
    color: #000000;
    line-height: 42px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .lecherWrap {
    display: flex;
    margin: 15px 0 30px 0;
  }

  .lecherBox {
    display: flex;
    align-items: center;
    align-content: space-between;
  }

  .lecherRight {
    width: 300px;
    display: flex;
    align-items: center;
    align-content: space-between;
    justify-content: flex-end;
  }

  .lecherAva {
    width: 36px;
    height: 36px;
    margin-right: 4px;
  }

  .lecherNames {
    width: calc(100vw - 360px);
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 24px;
    color: #999999;
    line-height: 28px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .publishDate {
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 24px;
    color: #999999;
    line-height: 28px;
    text-align: right;
    font-style: normal;
    text-transform: none;
  }

  .courseImgWrap {
    position: relative;
    width: 100%;
    height: 350px;
  }

  .courseImg {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }

  .courseImgMeng {
    border-radius: 0 0 10px 10px;
    position: absolute;
    bottom: 0;
    width: -webkit-fill-available;
    background: #0000007c;
    padding: 14px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .joinNumWrap {
    display: flex;
    align-items: center;
  }

  .dianbo {
    width: 34px;
    height: 34px;
    margin-right: 10px;
  }

  .joinNum {
    /* width: 236px; */
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 24px;
    color: #ffffff;
    line-height: 28px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .chapterCountsWrap {
    display: flex;
    align-items: center;
  }

  .chapterCounts {
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 24px;
    color: #ffffff;
    line-height: 28px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }

  .updateNum {
    color: #ffc60a;
  }
  .play_counts {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: #ffffff;
    line-height: 34px;
    img {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }
  }
  .title {
    display: flex;
    justify-content: space-between;
    height: 39px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 28px;
    color: #333333;
    line-height: 33px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    margin: 30px 0 14px;
    position: relative;
    /* z-index: 10; */
    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 2px;
      width: 155px;
      height: 16px;
      background: linear-gradient(
        90deg,
        #ffc60a 0%,
        rgba(255, 198, 10, 0) 100%
      );
      border-radius: 0px 0px 0px 0px;
    }
    span {
      z-index: 1;
    }
  }

  .courseDescMore {
    white-space: pre-wrap;
    font-family: PingFang SC, PingFang SC;
    font-weight: 300;
    font-size: 24px;
    color: #000000;
    line-height: 48px;
    overflow: hidden;
    position: relative;
    &::after {
      content: "…";
      position: absolute;
      bottom: 0;
      right: 0;
      padding-left: 6px;
      background: -webkit-linear-gradient(left, #fbf3e6, #fbf3e6);
      background: -moz-linear-gradient(left, #fbf3e6, #fbf3e6);
      background: -o-linear-gradient(left, #fbf3e6, #fbf3e6);
      background: linear-gradient(toright, #fbf3e6, #fbf3e6);
    }
  }
  .courseDesc {
    font-family: PingFang SC, PingFang SC;
    font-weight: 300;
    font-size: 24px;
    color: #000000;
    line-height: 48px;
    overflow: hidden;
  }
}
.pay_wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* height: 646px; */
  background: #f7f7f7;
  .pay_info {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 30px 40px 30px;
    width: 100vw;
    /* height: 432px; */
  }
  .pay_title {
    font-weight: 500;
    font-size: 24px;
    color: #999999;
    line-height: 36px;
  }
  .pay_fee {
    margin-top: 18px;
    font-family: Roboto, Roboto;
    font-weight: 700;
    font-size: 40px;
    color: #333;
    line-height: 40px;
    span {
      font-size: 20px;
    }
  }
  .pay_tip_no_refund {
    margin-bottom: 22px;
    font-weight: 500;
    font-size: 28px;
    color: #1994f4;
    line-height: 36px;
  }
  .exchange_tip {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 690px;
    /* height: 110px; */
  }
  .pay_wx_bar {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 690px;
    height: 110px;
    background: #ffffff;
    border-radius: 10px 10px 10px 10px;
    &.flex_column {
      margin-top: 20px;
      padding: 30px 25px;
      flex-direction: column;
      height: 150px;
      .courseName {
        width: 100%;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 36px;
        color: #000000;
        line-height: 42px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .lecherWrap {
        width: 100%;
        display: flex;
        /* margin: 15px 0 30px 0; */
      }
      .lecherBox {
        flex: 1;
        display: flex;
        align-items: center;
        align-content: space-between;
      }
      .lecherRight {
        flex: 1;
        display: flex;
        align-items: center;
        align-content: space-between;
        justify-content: flex-end;
      }
      .lecherAva {
        width: 36px;
        height: 36px;
        margin-right: 4px;
      }
      .lecherNames {
        width: calc(100vw - 360px);
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 24px;
        color: #999999;
        line-height: 28px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .publishDate {
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 24px;
        color: #999999;
        line-height: 28px;
        text-align: right;
        font-style: normal;
        text-transform: none;
      }
    }
    .exchange_field {
      padding: 0 25px;
      font-size: 36px;
      &.read_only {
        color: #cccccc;
      }
    }
    .pay_wx_left {
      display: flex;
      align-items: center;
      img {
        margin-right: 12px;
        width: 47px;
        height: 43px;
      }
    }
    .pay_wx_right {
      width: 36px;
      height: 36px;
    }
  }
  .pay_bar {
    padding: 30px;
    /* position: absolute;
    bottom: 0; */
    width: 100vw;
    height: 214px;
    box-shadow: 2px 0px 37px 0px rgba(0, 0, 0, 0.08);
    .btn_class {
      position: relative;
      bottom: 0;
      display: flex;
      .btn {
        // background-color: $baseColor;
        background: #1994f4;
        border: none;
        border-radius: 89px 89px 89px 89px;
        width: 100%;
        height: 100px;
      }
      .van-button--plain.van-button--primary {
        color: #ffffff;
        font-size: 28px;
        width: 690px;
        height: 87px;
        font-weight: 700;
      }
    }
    .tip_text {
      margin-top: 16px;
      text-align: center;
      font-weight: 400;
      font-size: 20px;
      color: #b4b4b4;
      line-height: 26px;
    }
  }
}
</style>

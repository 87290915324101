/*
 * @Author: jiameng15
 * @Date: 2021-07-09 09:38:43
 * @Last Modified by: jiameng15
 * @Last Modified time: 2024-12-03 17:13:28
 * 用户相关接口
 */

import request from "@/utils/request"; // 引入request,基于axios
import queryString from "querystring"; // post方法传参转型String
export function courseList(data) {
  return request({
    url: "/public/course/list",
    method: "get",
    params: data,
  });
}

export function getExchangeInfo(data) {
  return request({
    url: "/exchange/code/detail",
    method: "get",
    params: data,
  });
}

export function postExchange(data) {
  return request({
    url: "/exchange/operate",
    method: "post",
    data: queryString.stringify(data),
  });
}
